<style scoped lang="scss">
.yourMedia {
  max-height: 312px;
}
</style>

<template>
  <div class="grid px-4 gap-x-4 gap-y-0 grid-cols-2 yourMedia overflow-y-auto overflow-x-hidden">
    <div
      v-for="(file,index) in files"
      :key="index"
      @click="selectFile(index)"
      class="border-b rounded cursor-pointer flex border-primary-200 h-16 px-1.5 col-span-1 items-center justify-between group"
      :class="[selectedFile===index ? 'bg-primary-200' : 'hover:bg-primary-100']"
    >
      <div
        class="font-semibold text-sm text-primary-900 whitespace-nowrap overflow-ellipsis overflow-hidden"
        :class="{'font-bold': selectedFile===index}"
      >
        {{ file.title }}
      </div>

      <div class="flex items-center">
        <div v-if="playedItem===index">
          <div v-if="!status || status==='pause'" @click="setPlay(index)" class="cursor-pointer text-primary hover:text-red">
            <MusicPlay class="h-6 w-6" />
          </div>
          <div v-else-if="status==='seeking'" @click="setPause(index)" class="text-red">
            <CircularLoader class="h-6 w-6" />
          </div>
          <div v-else-if="status==='playing'" @click="setPause(index)" class="cursor-pointer text-red hover:text-primary">
            <MusicPause class="h-6 w-6" />
          </div>
        </div>
        <div v-else @click="setPlay(index)" class="cursor-pointer text-primary hover:text-red">
          <MusicPlay class="h-6 w-6" />
        </div>
      </div>
    </div>

    <audio @playing="playing" @pause="pause" @ended="ended" @seeking="seeking" @waiting="waiting" ref="audio"/>

    <AddFilesFooter :config="footerConfig" @actionBtnClick="actionBtnClick($event)"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CircularLoader from '@/components/base/icons/CircularLoader.vue'
import MusicPlay from '@/components/base/icons/MusicPlay.vue'
import MusicPause from '@/components/base/icons/MusicPause.vue'
import store from '@/services/store'
import { getURLfromPath } from '../../utility'
export default {
  name: 'YourMedia',
  components: {
    CircularLoader,
    MusicPlay,
    MusicPause,
    AddFilesFooter: () => import(/* webpackChunkName: "AddFilesFooter" */'../../AddFileFooter/AddFileFooter')
  },
  data () {
    return {
      uploadedMedia: store.state.app.uploadedMedia,
      selectedFile: '',
      playedItem: {},
      status: '',
      footerConfig: {
        message: 'All sounds are from Freesound.org and you can use them commercially for your projects. More details ',
        showHereLink: true,
        actionBtns: {
          'addFile': {
            label: 'Add file',
            type: 'submit',
            isDisabled: true,
            isHidden: false
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      modalProps: 'dialogs/modalProps'
    }),
    isReplacing: function() {
      return this.modalProps.isReplacing
    },
    activeFiles: function() {
      return _.pickBy(this.files, function(u) {
        return !(u.deleted)
      })
    },
    files () {
      let list = {}
      for (let key in this.uploadedMedia) {
        if (!this.uploadedMedia[key].deleted) {
          list[key] = this.uploadedMedia[key]
        }
      }
      return list
    }
  },
  methods: {
    selectFile (index) {
      let vm = this
      if (index === this.selectedFile) {
        return
      }
      this.$refs.audio.pause()
      this.status = 'pause'
      this.selectedFile = index
      // console.log('selectFile', index)
      if (!vm.files[index].url) {
        console.error('Processing this file')
      } else {
        store.commit('modal/setSelectedFile', { type: 'uploaded_media', key: index })
        this.footerConfig.actionBtns['addFile'].isDisabled = false
      }
    },
    setPlay (index) {
      let vm = this
      let turl = vm.files[index].url
      if (turl) {
        vm.status = 'seeking'
        getURLfromPath(turl)
          .then(function (url) {
            console.log('url is', url)
            vm.playedItem = index
            vm.$refs.audio.src = url
            vm.$refs.audio.play()
          })
      }
    },
    setPause (index) {
      this.$refs.audio.pause()
    },
    playing () {
      console.log('playing')
      this.status = 'playing'
    },
    pause () {
      console.log('paused')
      this.status = 'pause'
    },
    ended () {
      console.log('ended')
      this.status = ''
    },
    seeking () {
      console.log('seeking')
      this.status = 'seeking'
    },
    waiting () {
      console.log('wating')
      this.status = 'seeking'
    },
    actionBtnClick () {
      store.dispatch('modal/addFile', this.isReplacing)
    }
  }
}
</script>
